import { useCustomer } from '@customer/composables/useCustomer'
import { REGISTER_ROUTE_NAME } from '@customer/types/registration'

export default defineNuxtRouteMiddleware((to) => {
  const { localePath } = useT3Utils()
  const { isLoggedIn } = useCustomer()
  if (to.name === REGISTER_ROUTE_NAME && isLoggedIn.value) {
    return navigateTo(localePath())
  }
})
